import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanRiskManagement = () => {
  const data = [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/",
      title: "Program Kami"
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP"
    }
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan Risk Management
              </div>
              <h2>Pendahuluan</h2>
              <p>
                Manajemen risiko khususnya dalam dunia perbankan merupakan suatu
                keterampilan yang wajib dimiliki oleh setiap bankir. Hal ini
                didukung dengan ketentuan otoritas bahwa setiap insan bank harus
                memiliki kompetensi manajemen risiko. Manajemen risiko pada
                perbankan sangatlah penting guna mencegah kerugian atau
                meminimalisir potensi kerugian yang mungkin terjadi. Lingkungan
                internal dan eksternal bisnis perbankan yang senantiasa berubah
                dengan cepat menjadikan penerapan manajemen risiko yang memadai
                pada industri perbankan menjadi suatu keharusan. Sumber daya
                manusia khususnya di bidang perbankan merupakan aset vital yang
                perlu dikelola. Oleh karena itu, perlu diciptakan sumber daya
                manusia yang berkualitas dan berkompeten di bidang manajemen
                risiko serta mempunyai standar profesi dan kode etik yang baik,
                dibuktikan dengan memiliki sertifikasi manajemen risiko sesuai
                jenjangnya.
              </p>
              <h2>Tujuan</h2>
              <p>
                Peserta mampu memahami dan menjelaskan tentang Manajemen Risiko.
              </p>
              <h2>Kurikulum</h2>
              <p>
                Kurikulum dalam pelatihan ini yaitu Manajemen Risiko Perbankan,
                Regulasi, ERM, GRC.
              </p>
              <h2>Metode Program</h2>
              <p>Diskusi interaktif, case study dan sharing knowledge.</p>
              <h2>Rekomendasi Peserta</h2>
              <p>
                Pelatihan ini sangat direkomendasikan untuk diikuti oleh para
                karyawan di bidang Manajemen Risiko.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanRiskManagement;
