import activity1 from "../../assets/activity/activity_1.png";
import activity2 from "../../assets/activity/activity_2.png";
// import activity3 from "../../assets/activity/activity_3.png";
import activity4 from "../../assets/activity/activity_4.png";
import activity3 from "../../assets/activity/activity_5.jpeg";

export const activities = [
  {
    img: activity1,
    title:
      "PROGRAM PERSIAPAN UJI SERTIFIKASI KKNI KUALIFIKASI 4 BIDANG MANAJEMEN RISIKO PERBANKAN LSPP KERJASAMA FiRM dengan BANK SAHABAT SAMPOERNA BANK SAHABAT SAMPOERNA TRAINING CENTER, KELAPA GADING, 20-21 Februari 2024",
    desc: "FiRM dan Bank Sahabat Sampoerna menyelenggarakan kerjasama dalam Program Persiapan Uji Sertifikasi KKNI Kualifikasi 4 Bidang Manajemen Risiko Perbankan LSPP pada hari Selasa – Rabu, 20 – 21 Februari 2024 di Bank Sahabat Sampoerna Training Center, Kelapa Gading Jakarta dengan diikuti oleh 26 (dua puluh enam) orang peserta.",
  },
  {
    img: activity2,
    title:
      "PROGRAM PERSIAPAN UJI SERTIFIKASI KKNI KUALIFIKASI 4 BIDANG MANAJEMEN RISIKO PERBANKAN LSPP KERJASAMA FiRM dengan BANK SAHABAT SAMPOERNA BANK SAHABAT SAMPOERNA TRAINING CENTER, KELAPA GADING, 20-21 Februari 2024",
    desc: "FiRM dan Bank Sahabat Sampoerna menyelenggarakan kerjasama dalam Program Persiapan Uji Sertifikasi KKNI Kualifikasi 4 Bidang Manajemen Risiko Perbankan LSPP pada hari Selasa – Rabu, 20 – 21 Februari 2024 di Bank Sahabat Sampoerna Training Center, Kelapa Gading Jakarta dengan diikuti oleh 26 (dua puluh enam) orang peserta.",
  },
  {
    img: activity3,
    title:
      "Program Persiapan Uji Sertifikasi Manajemen Risiko Perbankan Kerjasama Bank Papua dengan FiRM dilaksanakan tanggal 10-11 Juni 2024 di Hotel Ashley Jakarta",
    desc: "Pelaksanaan Uji Sertifikasi Manajemen Risiko Perbankan Jenjang 6 untuk Komisaris Bank Papua tanggal 13 Juni 2024 di Gedung LSPP dengan hasil kompeten",
  },
  {
    img: activity4,
    title:
      "PROGRAM PERSIAPAN UJI SERTIFIKASI KKNI KUALIFIKASI 5 BIDANG MANAJEMEN RISIKO PERBANKAN LSPP KERJASAMA FiRM dengan BANK SAHABAT SAMPOERNA ONLINE TRAINING (APLIKASI ZOOM), 12-13 Februari 2024",
    desc: "FiRM dan Bank Sahabat Sampoerna menyelenggarakan kerjasama dalam Program Persiapan Uji Sertifikasi KKNI Kualifikasi 5 Bidang Manajemen Risiko Perbankan LSPP pada hari Senin – Selasa, 12 – 13 Februari 2024 melalui Aplikasi Zoom dengan diikuti oleh 9 (sembilan) orang peserta.",
  },
];

export const contentServices = [
  {
    id: 1,
    title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Bankir-bankir di industri perbankan Indonesia saat ini membutuhkan ketrampilan pengelolaan manajemen risiko sejalan dengan penetapan oleh OJK dimana setiap orang dalam bank harus memiliki kompetensi manajemen risiko sebagaimana tertuang dalam POJK No. 17/POJK.03/2014 tentang Penerapan Manajemen Risiko Terintegrasi bagi Konglomerasi Keuangan dan POJK No. 18/POJK.03/2016 tentang Penerapan Manajemen Risiko Bagi Bank Umum. Pencegahan terjadinya potensi kerugian merupakan tujuan penting dari manajemen risiko perbankan. Perubahan yang cepat berubah sering terjadi di lingkungan internal dan eksternal perbankan sehingga dibutuhkan penerapan manajemen risiko secara benar.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Mempersiapkan peserta untuk menghadapi Sertifikasi Ulang/Resertifikasi KKNI bidang Manajemen Risiko Perbankan,</li><li>Memberikan pemahaman komprehensif tentang Manajemen Risiko Perbankan.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program/Unit Kompetensi dari masing-masing Jenjang Kualifikasi sudah disesuaikan dengan SKKNI.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li><li>Simulasi Ujian</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Persiapan Uji Sertifikasi ini ditujukan bagi Pengurus, Pejabat, Direktur dan Komisaris bank. Program Persiapan Uji Sertifikasi ini terdiri dari beberapa Kualifikasi dengan kewajiban bagi pemegang sertifikat untuk mengikuti program pemeliharaan sesuai dengan kualifikasi sertifikatnya.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers untuk Pembekalan Manajemen Risiko.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 2,
    title:
      "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Sertifikasi Manajemen Risiko adalah merupakan kewajiban bagi Karyawan Bank dengan jenjang kualifikasi sesuai jabatan yang diemban yang didasarkan kepada ketentuan Bank Indonesia /OJK, agar para Pejabat yang berkewajiban memiliki Sertifikat Manajemen Risiko lebih siap dalam menghadapi ujian sertifikasi, kami menawarkan memberikan pelatihan agar Pejabat Bank mendapat predikat Kompeten dari BSMR. Melalui Proposal ini kami menawarkan bentuk pelatihan persiapan Ujian Sertifikasi Manajemen Risiko Jenjang Kualifikasi 4-5 yang diselenggarakan oleh BSMR. Materi pelatihan disesuaikan dengan materi ujian yang diselenggarakan BSMR dan pengajar yang akan memberikan pelatihan telah berpengalaman di berbagai Bank diseluruh Indonesia.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Mempersiapkan peserta untuk menghadapi Sertifikasi Ulang/Resertifikasi KKNI bidang Manajemen Risiko Perbankan,</li><li>Memberikan pemahaman komprehensif tentang Manajemen Risiko Perbankan.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program/Unit Kompetensi dari masing-masing Jenjang Kualifikasi sudah disesuaikan dengan SKKNI.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li><li>Simulasi Ujian</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Persiapan Uji Sertifikasi ini ditujukan bagi Pengurus dan Pejabat bank. Program Persiapan Uji Sertifikasi ini terdiri dari beberapa Kualifikasi dengan kewajiban bagi pemegang sertifikat untuk mengikuti program pemeliharaan sesuai dengan kualifikasi sertifikatnya.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers untuk Pembekalan Manajemen Risiko.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 3,
    title: "Bidang General Banking Tingkat 1-3, LSPP",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Persiapan menghadapi era industri 4.0 perbankan nasional harus meningkatkan kapabilitas untuk dapat bersaing dengan negara-negara di dunia. Sumber Daya Manusia (SDM) merupakan salah satu komponen yang perlu dipersiapkan, karena SDM tidak hanya sebagai pemberi nilai tambah tetapi juga merupakan ujung tombak (first line of defense) dalam menghadapi berbagai tantangan yang ada dalam industri perbankan baik nasional maupun internasional. Kompetensi kerja diperlukan dalam rangka pemberdayaan SDM yang optimal dan tepat. Kompetensi SDM yang bervariasi menuntut adanya suatu standar kompetensi kerja, yang dapat dijadikan sumber alat ukur untuk menilai kompetensi yang dikuasainya. Standar kompetensi yang umum berlaku di Indonesia disebut dengan Standar Kompetensi Kerja Nasional Indonesia (SKKNI). Sektor Keuangan Sub Sektor Perbankan telah memiliki SKKNI 9 (sembilan) bidang profesi yaitu Manajemen Risiko, Treasury, Wealth Management, Audit Intern Bank, Compliance, Operation, Funding and Services, Operation, dan General Banking yang disusun oleh Ikatan Bankir Indonesia (IBI). SKKNI bidang General Banking disusun karena bidang kerja serta jumlah bankir yang terdapat dalam General Banking di suatu bank umumnya lebih banyak dibandingkan dengan bidang spesialis. Berdasarkan hal tersebut maka perlu adanya standardisasi kompetensi di bidang General Banking.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Mempersiapkan peserta untuk menghadapi uji Sertifikasi KKNI bidang General Banking,</li><li>Memberikan pemahaman komprehensif tentang General Banking.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program dari masing-masing Tingkat / Level sudah disesuaikan dengan SKKNI.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li><li>Simulasi Ujian</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Persiapan Uji Sertifikasi ini ditujukan bagi pengurus dan pejabat bank. Program Persiapan Uji Sertifikasi ini terdiri dari beberapa Kualifikasi dengan kewajiban bagi pemegang sertifikat untuk mengikuti program pemeliharaan sesuai dengan kualifikasi sertifikatnya.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi General Banking.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 4,
    title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Bankir-bankir di industri perbankan Indonesia saat ini membutuhkan ketrampilan pengelolaan manajemen risiko sejalan dengan penetapan oleh OJK dimana setiap orang dalam bank harus memiliki kompetensi manajemen risiko sebagaimana tertuang dalam POJK No. 17/POJK.03/2014 tentang Penerapan Manajemen Risiko Terintegrasi bagi Konglomerasi Keuangan dan POJK No. 18/POJK.03/2016 tentang Penerapan Manajemen Risiko Bagi Bank Umum. Pencegahan terjadinya potensi kerugian merupakan tujuan penting dari manajemen risiko perbankan. Perubahan yang cepat berubah sering terjadi di lingkungan internal dan eksternal perbankan sehingga dibutuhkan penerapan manajemen risiko secara benar.",
      },
      {
        title: "Tujuan Program",
        description:
          "Mempersiapkan peserta untuk menghadapi Sertifikasi Ulang/Resertifikasi KKNI bidang Manajemen Risiko Perbankan,\nMemberikan pemahaman komprehensif tentang Manajemen Risiko Perbankan.",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program/Unit Kompetensi dari masing-masing Jenjang Kualifikasi sudah disesuaikan dengan SKKNI.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li><li>Simulasi Ujian</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Persiapan Sertifikasi Ulang / Resertifikasi ini ditujukan bagi Pengurus, Pejabat, Direktur dan Komisaris bank yang sudah memiliki Sertifikat Kompetensi namun akan / akan jatuh tempo sehingga perlu dilakukan Sertifikasi Ulang. Program Persiapan ini terdiri dari beberapa Kualifikasi dengan kewajiban bagi pemegang sertifikat untuk mengikuti program pemeliharaan sesuai dengan kualifikasi sertifikatnya.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers untuk Pembekalan Manajemen Risiko.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 5,
    title:
      "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Sertifikasi Manajemen Risiko adalah merupakan kewajiban bagi Karyawan Bank dengan jenjang kualifikasi sesuai jabatan yang diemban yang didasarkan kepada ketentuan Bank Indonesia /OJK, agar para Pejabat yang berkewajiban memiliki Sertifikat Manajemen Risiko lebih siap dalam menghadapi ujian sertifikasi, kami menawarkan memberikan pelatihan agar Pejabat Bank mendapat predikat Kompeten dari BSMR. Melalui Proposal ini kami menawarkan bentuk pelatihan persiapan Ujian Sertifikasi Manajemen Risiko Jenjang Kualifikasi 4-5 yang diselenggarakan oleh BSMR. Materi pelatihan disesuaikan dengan materi ujian yang diselenggarakan BSMR dan pengajar yang akan memberikan pelatihan telah berpengalaman di berbagai Bank diseluruh Indonesia.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Meningkatkan kompetensi Sumber Daya Manusia di Bank.</li><li>Meningkatkan kompetensi Sumber Daya Manusia di Bank.</li><li>Meningkatkan kompetensi Sumber Daya Manusia di Bank.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program/Unit Kompetensi dari masing-masing Jenjang Kualifikasi sudah disesuaikan dengan SKKNI.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li><li>Simulasi Ujian</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Persiapan Sertifikasi Ulang / Resertifikasi ini ditujukan bagi Pengurus dan Pejabat bank yang sudah memiliki Sertifikat Kompetensi namun akan / sudah jatuh tempo sehingga perlu dilakukan Sertifikasi Ulang. Program Persiapan ini terdiri dari beberapa Kualifikasi dengan kewajiban bagi pemegang sertifikat untuk mengikuti program pemeliharaan sesuai dengan kualifikasi sertifikatnya.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers untuk Pembekalan Manajemen Risiko.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 6,
    title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Surat Edaran OJK Nomor 28/SEOJK.03/2022 Tentang Sertifikasi Manajemen Risiko Bagi Sumber Daya Manusia Bank Umum tertulis bahwa Program pemeliharaan (refreshment) Sertifikasi Manajemen Risiko yang selanjutnya disebut Program Pemeliharaan merupakan program yang dilaksanakan dengan tujuan untuk memelihara kompetensi pemegang atau pemilik Sertifikat Manajemen Risiko di bidang manajemen risiko Bank. Program pemeliharaan dilaksanakan dengan memenuhi ketentuan dilakukan secara berkala paling sedikit 1 (satu) kali dalam jangka waktu 1 (satu) tahun setelah diterbitkan Sertifikat dan bentuk kegiatan program pemeliharaan antara lain berupa in-house training, seminar, sosialisasi ketentuan dari otoritas yang berwenang, workshop, lokakarya, e-learning, dan/atau portofolio pekerjaan, yang terkait dengan bidang manajemen risiko Bank.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Memelihara kompetensi pemegang atau pemilik Sertifikat Manajemen Risiko Perbankan,</li><li>Memberikan pemahaman tentang perkembangan manajemen risiko Perbankan saat ini.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program/Unit Kompetensi yang akan disampaikan adalah topik yang relevan dari masing-masing Unit Kompetensi terkini dan atau berdasarkan permintaan dari Pengguna",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li><li>Simulasi Ujian</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Pemeliharaan Sertifikasi ini ditujukan bagi pengurus dan pejabat bank yang memiliki Sertifikat Kompetensi Manajemen Risiko.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers untuk Pembekalan Manajemen Risiko.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 7,
    title:
      "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Surat Edaran OJK Nomor 28/SEOJK.03/2022 Tentang Sertifikasi Manajemen Risiko Bagi Sumber Daya Manusia Bank Umum tertulis bahwa Program pemeliharaan (refreshment) Sertifikasi Manajemen Risiko yang selanjutnya disebut Program Pemeliharaan merupakan program yang dilaksanakan dengan tujuan untuk memelihara kompetensi pemegang atau pemilik Sertifikat Manajemen Risiko di bidang manajemen risiko Bank. Program pemeliharaan dilaksanakan dengan memenuhi ketentuan dilakukan secara berkala paling sedikit 1 (satu) kali dalam jangka waktu 1 (satu) tahun setelah diterbitkan Sertifikat dan bentuk kegiatan program pemeliharaan antara lain berupa in-house training, seminar, sosialisasi ketentuan dari otoritas yang berwenang, workshop, lokakarya, e-learning, dan/atau portofolio pekerjaan, yang terkait dengan bidang manajemen risiko Bank.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Memelihara kompetensi pemegang atau pemilik Sertifikat Manajemen Risiko Perbankan,</li><li>Memberikan pemahaman tentang perkembangan manajemen risiko Perbankan saat ini.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program/Unit Kompetensi yang akan disampaikan adalah topik yang relevan dari masing-masing Unit Kompetensi terkini dan atau berdasarkan permintaan dari Pengguna",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study & sharing knowledge</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Program Pemeliharaan Sertifikasi ini ditujukan bagi pengurus dan pejabat bank yang memiliki Sertifikat Kompetensi Manajemen Risiko.",
      },
      {
        title: "Fasilitator Program",
        description:
          "Kami memiliki fasilitator berpengalaman dari industri perbankan dan regulator dimana para fasilitator tersebut sudah memiliki Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers untuk Pembekalan Manajemen Risiko.",
      },
      {
        title: "Investasi Program",
        description:
          "Kami memberikan paket investasi program yang menarik dengan pelayanan yang sangat membantu anda dalam meningkatkan kompetensi Sumber Daya Manusia di Bank.",
      },
    ],
  },
  {
    id: 8,
    title: "General Banking untuk Calon Bankir",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Para Pegawai Perbankan dalam era industri 4.0 saat ini harus memiliki kapabilitas yang tinggi bukan hanya kemampuan dalam menjalankan bisnis Bank. Hal tersebut dikarenakan Pegawai Perbankan memiliki peran penting dalam mendukung keberhasilan pencapaian visi dan misi suatu Bank maka Kualitas dari para Pegawai Bank yang baik menjadi penting untuk mencapai misi tersebut. Upaya untuk mencapai kinerja tersebut tentunya harus sejalan dengan upaya untuk meningkatkan pengetahuan dalam pemahaman tentang General Banking, Kredit, Manajemen Risiko Perbankan, dan Softskill. Diharapkan, dengan mengikuti program ini dapat memberikan pengetahuan kepada para Peserta mengenai Dasar-Dasar Perbankan.",
      },
      {
        title: "Tujuan Program",
        description:
          "<div>Program pelatihan ini bertujuan untuk meningkatkan kemampuan Sumber Daya Manusia khususnya untuk para Staff seperti:</div><ol><li>Meningkatkan kemampuan Sumber Daya Manusia khususnya untuk para Staff seperti:</li><li>Memahami tentang General Banking,</li><li>Memahami tentang Kredit,</li><li>Memahami tentang Risk Management.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum Program yang akan diberikan yaitu General Banking, Kredit, Risk Management, Softskill.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif,</li><li>Case study,</li><li>Sharing knowledge.</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "<ol><li>Pelatihan ini sangat direkomendasikan untuk diikuti oleh:</li><li>Pegawai Dasar,</li><li>Calon Pegawai Dasar.</li></ol>",
      },
    ],
  },
  {
    id: 9,
    title: "Akuntansi dan Laporan Keuangan Bank",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Laporan keuangan Bank memberikan gambaran dari kegiatan Bank. Akuntansi bank berperan untuk memilih, mengukur, dan melakukan agregasi aktivitas bank ke dalam laporan keuangan Bank. Para pengguna informasi laporan keuangan bank harus memahami bahwa laporan keuangan bank sangat dipengaruhi oleh bisnis bank dan sistem akuntansi bank.",
      },
      {
        title: "Tujuan Program",
        description:
          "<ol><li>Memahami tentang akuntansi dan laporan keuangan bank</li><li>Memahami dan menganalisis akuntansi dan laporan keuangan bank</li><li>Mengevaluasi potensi pemberian hasil terbaik dari calon penerima penjaminan.</li></ol>",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum dalam pelatihan ini yaitu Laporan Posisi Keuangan, Laporan Laba / Rugi, Laporan Perubahan Ekuitas, Laporan Arus Kas, Analisis Rasio.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study</li><li>Sharing knowledge</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "<ol><li>Pelatihan ini sangat direkomendasikan untuk diikuti oleh:</li><li>Pegawai di Unit Kerja Akuntnasi</li><li>Calon Pegawai Bank</li></ol>",
      },
    ],
  },
  {
    id: 10,
    title: "Pengelolaan Kredit dalam Industri Perbankan",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Sebagai core business dalam industri perbankan, kredit memegang peran penting didalam menjaga dan memelihara keberlangsungan kegiatan usaha bisnis bank. Pengelolaan kredit yang profesional dengan tetap memperhatikan prinsip prudential banking wajib dimiliki dan dijalankan oleh setiap sumber daya manusia perbankan. Guna mendukung pengelolaan kredit yang profesional, maka diperlukan pengetahuan yang memadai tentang tahapan, proses serta prosedur pengelolaan kredit.",
      },
      {
        title: "Tujuan",
        description:
          "<ol><li>Melatih dan menghasilkan karyawan yang handal dan kompeten dalam melakukan analisis kredit untuk semua produk dan segmen kredit.</li></ol>",
      },
      {
        title: "Kurikulum",
        description:
          "Kurikulum dalam pelatihan ini mencakup: Overview Kredit, Analisa Kredit, Hukum Kredit dan Penjaminan, Administrasi Kredit, Restrukturisasi Kredit dan NPL.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study</li><li>Sharing knowledge</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "<ol><li>Pelatihan ini sangat direkomendasikan untuk diikuti oleh:</li><li>Para Analis Kredit</li><li>Para AO Kredit</li></ol>",
      },
    ],
  },
  {
    id: 11,
    title: "Frontliner & Operation",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Para Pegawai Frontliner & Operation dalam era industri 4.0 saat ini harus memiliki kapabilitas yang tinggi bukan hanya bertugas sebagai frontliner. Hal tersebut dikarenakan Pegawai Frontliner & Operation memiliki peran penting dalam mendukung keberhasilan pencapaian visi dan misi Bank, maka Kualitas dari para Pegawai Bank yang baik menjadi penting untuk mencapai misi tersebut. Upaya untuk mencapai kinerja tersebut tentunya harus sejalan dengan upaya untuk meningkatkan pengetahuan, memiliki skill dan ketrampilan dalam pelayanan dan handling complaints. mengingat semakin kompleks transaksi bisnis perbankan maka semakin kompetensi dan skill para pegawai frontliner & operation perlu ditingkatkan.",
      },
      {
        title: "Tujuan Program",
        description:
          "Program ini bertujuan untuk meningkatkan kompetensi Pegawai Frontliner & Operation sehingga pada gilirannya membuat bank mampu bertahan di atas gelombang perubahan.",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum dalam pelatihan ini mencakup: Produk Dana dan Jasa, Service Excellence, Communication and Negotiation Skill, Professional Grooming, Cross Selling and Handling Complaints.",
      },
      {
        title: "Metode Program",
        description:
          "Diskusi interaktif, role play, case study dan sharing knowledge",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "<div>Pelatihan ini sangat direkomendasikan untuk diikuti oleh:</div><ol><li>Para Customer Service Bank</li><li>Para Teller Bank</li><li>Para Pegawai Operasional Cabang Bank</li></ol>",
      },
    ],
  },
  {
    id: 12,
    title: "Pelatihan Implementasi PSAK 71, 72, dan 73",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Aturan baru tentang Pernyataan Standar Akuntansi Keuangan (PSAK) 71, 72 dan 73 akan mulai diimplementasikan. Ketiga PSAK ini merupakan bagian dari usaha otoritas akuntasi yaitu Dewan Standar Akuntansi Keuangan (DSAK) untuk mengadopsi sistem dari International Financial Reporting Standards (IFRS). Revisi pada standar pelaporan ini adalah sebagai respons terhadap sejumlah risiko yang kini dihadapi perusahaan-perusahaan, khususnya sektor keuangan atau finansial terhadap risiko kegagalan pembayaran kredit.",
      },
      {
        title: "Tujuan Program",
        description:
          "Peserta mampu memahami dan menjelaskan tentang Perkembangan terbaru tentang PSAK 71, 72 dan 73",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum dalam pelatihan ini mencakup: PSAK 71, PSAK 72, dan PSAK 73.",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study</li><li>Sharing knowledge</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Pelatihan ini sangat direkomendasikan untuk diikuti oleh para karyawan di bidang Akuntansi dan Keuangan.",
      },
    ],
  },
  {
    id: 13,
    title: "Risk Management Program",
    content: [
      {
        title: "Pendahuluan",
        description:
          "Manajemen risiko khususnya dalam dunia perbankan merupakan suatu keterampilan yang wajib dimiliki oleh setiap bankir. Hal ini didukung dengan ketentuan otoritas bahwa setiap insan bank harus memiliki kompetensi manajemen risiko. Manajemen risiko pada perbankan sangatlah penting guna mencegah kerugian atau meminimalisir potensi kerugian yang mungkin terjadi. Lingkungan internal dan eksternal bisnis perbankan yang senantiasa berubah dengan cepat menjadikan penerapan manajemen risiko yang memadai pada industri perbankan menjadi suatu keharusan. Sumber daya manusia khususnya di bidang perbankan merupakan aset vital yang perlu dikelola. Oleh karena itu, perlu diciptakan sumber daya manusia yang berkualitas dan berkompeten di bidang manajemen risiko serta mempunyai standar profesi dan kode etik yang baik, dibuktikan dengan memiliki sertifikasi manajemen risiko sesuai jenjangnya.",
      },
      {
        title: "Tujuan Program",
        description:
          "Peserta mampu memahami dan menjelaskan tentang Manajemen Risiko.",
      },
      {
        title: "Kurikulum Program",
        description:
          "Kurikulum dalam pelatihan ini mencakup: Manajemen Risiko Perbankan, Regulasi, Enterprise Risk Management (ERM), Governance, Risk, and Compliance (GRC).",
      },
      {
        title: "Metode Program",
        description:
          "<ol><li>Diskusi interaktif</li><li>Case study</li><li>Sharing knowledge</li></ol>",
      },
      {
        title: "Rekomendasi Peserta",
        description:
          "Pelatihan ini sangat direkomendasikan untuk diikuti oleh para karyawan di bidang Manajemen Risiko",
      },
    ],
  },
  {
    id: 14,
    title: "Soft Skills",
    content: [
      {
        title: "Pendahuluan",
        description:
          "FiRM memiliki banyak Program dan juga fasilitator yang kompeten untuk Modul Soft Skills yang akan membantu para Pegawai Bank dalam menjalankan aktivitas kerja sehari-hari. Silahkan hubungi kami untuk penjelasan lebih dalam mengenai modul Soft Skills.",
      },
    ],
  },
];
