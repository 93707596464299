import React from "react";
import MainLayout from "../components/layout/MainLayout";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";
import { Col, Container, Row } from "react-bootstrap";

const DewanPenasehat = () => {
  return (
    <MainLayout>
      <BreadcrumbSection label="TENTANG KAMI" title="Dewan Penasihat" />
      <Container>
        <Row className="about-us_container">
          <Col lg={12}>
            <p>
              FiRM memiliki Dewan Penasehat yang memberikan masukan yang baik
              dan membangun. Para Dewan Penasehat ini terdiri dari para Pejabat
              Perbankan di Indonesia.
            </p>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default DewanPenasehat;
