import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanFrontliner = () => {
  const data = [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/",
      title: "Program Kami"
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP"
    }
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan Frontliner
              </div>
              <h2>Pendahuluan</h2>
              <p>
                Para Pegawai Frontliner & Operation dalam era industri 4.0 saat
                ini harus memiliki kapabilitas yang tinggi bukan hanya bertugas
                sebagai frontliner. Hal tersebut dikarenakan Pegawai Frontliner
                & Operation memiliki peran penting dalam mendukung keberhasilan
                pencapaian visi dan misi Bank, maka Kualitas dari para Pegawai
                Bank yang baik menjadi penting untuk mencapai misi tersebut.
              </p>
              <p>
                Upaya untuk mencapai kinerja tersebut tentunya harus sejalan
                dengan upaya untuk meningkatkan pengetahuan, memiliki skill dan
                ketrampilan dalam pelayanan dan handling complaints. Mengingat
                semakin kompleks transaksi bisnis perbankan maka semakin
                kompetensi dan skill para pegawai frontliner & operation perlu
                ditingkatkan.
              </p>
              <h2>Tujuan</h2>
              <p>
                Program ini bertujuan untuk meningkatkan kompetensi Pegawai
                Frontliner & Operation sehingga pada gilirannya membuat bank
                mampu bertahan di atas gelombang perubahan.
              </p>
              <h2>Kurikulum</h2>
              <p>
                Kurikulum dalam pelatihan ini yaitu Produk Dana dan Jasa,
                Service Excellence, Communication and Negotiation Skill,
                Professional Grooming, Cross Selling and Handling Complaints.
              </p>
              <h2>Metode Program</h2>
              <p>
                Diskusi interaktif, role play, case study dan sharing knowledge
              </p>
              <h2>Rekomendasi Peserta</h2>
              <ol>
                <li>Para Customer Service Bank</li>
                <li>Para Teller Bank</li>
                <li>Para Pegawai Operasional Cabang Bank</li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanFrontliner;
