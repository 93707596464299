import "../../style/service.css";
export default function BreadcrumbServiceSection(props) {
  const { data } = props;
  return (
    <div className="breadcrumb-container mb-3">
      {data.map((item, index) => (
        <div className="breadcrumb-item" key={index}>
          <a href={item.link}>{item.title}</a>
        </div>
      ))}
    </div>
  );
}
