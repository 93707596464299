import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const PemeliharaanSertifikasiBSMR = () => {
  const data = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/",
      title: "Program Kami",
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    },
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_subtitle-service">
                Program Pemeliharaan Sertifikasi (Refreshment)
              </div>
              <div className="content_subtitle-service">
                Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR
                (kolaborasi)
              </div>
              <div className="content_subtitle-service">Pendahuluan</div>
              <p>
                Surat Edaran OJK Nomor 28/SEOJK.03/2022 Tentang Sertifikasi
                Manajemen Risiko Bagi Sumber Daya Manusia Bank Umum tertulis
                bahwa Program pemeliharaan (refreshment) Sertifikasi Manajemen
                Risiko yang selanjutnya disebut Program Pemeliharaan merupakan
                program yang dilaksanakan dengan tujuan untuk memelihara
                kompetensi pemegang atau pemilik Sertifikat Manajemen Risiko di
                bidang manajemen risiko Bank. Program pemeliharaan dilaksanakan
                dengan memenuhi ketentuan dilakukan secara berkala paling
                sedikit 1 (satu) kali dalam jangka waktu 1 (satu) tahun setelah
                diterbitkan Sertifikat dan bentuk kegiatan program pemeliharaan
                antara lain berupa in-house training, seminar, sosialisasi
                ketentuan dari otoritas yang berwenang, workshop, lokakarya,
                e-learning, dan/atau portofolio pekerjaan, yang terkait dengan
                bidang manajemen risiko Bank.
              </p>

              <div className="content_subtitle-service">Tujuan Program</div>
              <ul>
                <li>
                  Memelihara kompetensi pemegang atau pemilik Sertifikat
                  Manajemen Risiko Perbankan
                </li>
                <li>
                  Memberikan pemahaman tentang perkembangan manajemen risiko
                  Perbankan saat ini
                </li>
              </ul>

              <div className="content_subtitle-service">Kurikulum Program</div>
              <p>
                Kurikulum Program/Unit Kompetensi yang akan disampaikan adalah
                topik yang relevan dari masing-masing Unit Kompetensi terkini
                dan atau berdasarkan permintaan dari Pengguna
              </p>

              <div className="content_subtitle-service">Metode Program</div>
              <ul>
                <li>Diskusi interaktif</li>
                <li>Case study & sharing knowledge</li>
              </ul>

              <div className="content_subtitle-service">
                Rekomendasi Peserta
              </div>
              <p>
                Program Pemeliharaan Sertifikasi ini ditujukan bagi pengurus dan
                pejabat bank yang memiliki Sertifikat Kompetensi Manajemen
                Risiko.
              </p>

              <div className="content_subtitle-service">
                Fasilitator Program
              </div>
              <p>
                Kami memiliki fasilitator berpengalaman dari industri perbankan
                dan regulator dimana para fasilitator tersebut sudah memiliki
                Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers
                untuk Pembekalan Manajemen Risiko.
              </p>

              <div className="content_subtitle-service">Investasi Program</div>
              <p>
                Kami memberikan paket investasi program yang menarik dengan
                pelayanan yang sangat membantu anda dalam meningkatkan
                kompetensi Sumber Daya Manusia di Bank.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default PemeliharaanSertifikasiBSMR;
