import "./../../style/breadcrumb.css";

export default function BreadcrumbSection(props) {
  const { label, title } = props;

  return (
    <div className="breadcrumb-section">
      <div className="breadcrumb-label">{label}</div>
      <h2 className="breadcrumb-title h2">{title}</h2>
    </div>
  );
}
