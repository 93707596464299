import client1 from "../../assets/clients/Picture1.png";
import client2 from "../../assets/clients/Picture2.png";
import client3 from "../../assets/clients/Picture3.png";
import client4 from "../../assets/clients/Picture4.png";
import client5 from "../../assets/clients/Picture5.png";
import client6 from "../../assets/clients/Picture6.png";

export const menu = [
  {
    title: "Beranda",
    link: "/",
    statusActive: false,
    sub: [],
  },
  {
    title: "Aktifitas Kami",
    link: "/",
    statusActive: false,
    sub: [
      {
        title: "Program Persiapan Uji Sertifikasi",
        subsubmenu: [
          {
            title:
              "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
            subsubsubmenu: [
              {
                title: "Pendampingan hingga dinyatakan Kompeten",
                link: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-manajemen-risiko-lspp/1",
              },
            ],
            link: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-manajemen-risiko-lspp/1",
          },
          {
            title:
              "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
            subsubsubmenu: [],
            link: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-manajemen-risiko-bsmr/2",
          },
          {
            title: "Bidang General Banking Tingkat 1-3, LSPP",
            subsubsubmenu: [],
            link: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-general-banking-lspp/3",
          },
        ],
      },
      {
        title: "Program Persiapan Sertifikasi Ulang (Resertifikasi)",
        subsubmenu: [
          {
            title:
              "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
            subsubsubmenu: [
              {
                title: "Pendampingan hingga dinyatakan Kompeten",
                link: "/aktifitas-kami/program-persiapan-sertifikasi-ulang/bidang-manajemen-risiko-lspp/4",
              },
            ],
            link: "/aktifitas-kami/program-persiapan-sertifikasi-ulang/bidang-manajemen-risiko-lspp/4",
          },
          {
            title:
              "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
            subsubsubmenu: [],
            link: "/aktifitas-kami/program-persiapan-sertifikasi-ulang/bidang-manajemen-risiko-bsmr/5",
          },
        ],
      },
      {
        title: "Program Pemeliharaan (Refreshment) Sertifikasi",
        subsubmenu: [
          {
            title:
              "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
            subsubsubmenu: [],
            link: "/aktifitas-kami/program-pemeliharaan-sertifikasi/bidang-manajemen-risiko-lspp/6",
          },
          {
            title:
              "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
            subsubsubmenu: [],
            link: "/aktifitas-kami/program-pemeliharaan-sertifikasi/bidang-manajemen-risiko-bsmr/7",
          },
        ],
      },
      {
        title: "Program Pendidikan dan Pelatihan",
        subsubmenu: [
          {
            title: "General Banking untuk Calon Bankir",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/general-banking-untuk-calon-bankir/8",
          },
          {
            title: "Risk Management Program",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/risk-management-program/13",
          },
          {
            title: "Akuntansi dan Laporan Keuangan Bank",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/akuntansi-dan-laporan-keuangan-bank/9",
          },
          {
            title: "PSAK 71,72 & 73",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/psak-71-72-73/12",
          },
          {
            title: "Credit Management",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/credit-management/10",
          },
          {
            title: "Frontliner & Operation",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/frontliner-operation/11",
          },
          {
            title: "Soft Skills",
            link: "/aktifitas-kami/program-pendidikan-dan-pelatihan/soft-skills/14",
          },
        ],
      },
    ],
  },
  {
    title: "Berita & Pengumuman",
    link: "/berita-perbankan",
    statusActive: false,
    sub: [],
  },
  {
    title: "Peraturan Regulator",
    link: "/regulasi",
    statusActive: false,
    sub: [],
  },
  {
    title: "Tentang Kami",
    link: "#",
    statusActive: false,
    sub: [
      {
        title: "Sekilas Tentang FiRM",
        subsubmenu: [],
        link: "/tentang-kami/sekilas-tentang-firm",
      },
      {
        title: "Visi Misi Moto",
        subsubmenu: [],
        link: "/tentang-kami/visi-misi-moto",
      },
      {
        title: "Dewan Penasehat",
        subsubmenu: [],
        link: "/tentang-kami/dewan-penasehat",
      },
      {
        title: "Fasilitator",
        subsubmenu: [],
        link: "/tentang-kami/fasilitator",
      },
    ],
  },
  {
    title: "Gallery & Testimoni",
    link: "/galeri-dan-testimonials",
    statusActive: false,
    sub: [],
  },
];

export const clients = [
  {
    title: "Client 1",
    img: client1,
  },
  {
    title: "Client 2",
    img: client2,
  },
  {
    title: "Client 3",
    img: client3,
  },
  {
    title: "Client 4",
    img: client4,
  },
  {
    title: "Client 5",
    img: client5,
  },
  {
    title: "Client 6",
    img: client6,
  },
];
