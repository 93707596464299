import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanSoftSkill = () => {
  const data = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/",
      title: "Program Kami",
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    },
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan Soft Skills
              </div>
              <p>
                FiRM memiliki banyak Program dan juga fasilitator yang kompeten untuk Modul Soft Skills yang akan membantu para Pegawai Bank dalam menjalankan aktivitas kerja sehari-hari.
              </p>
              <p>
                Silahkan hubungi kami untuk penjelasan lebih dalam mengenai modul Soft Skills.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanSoftSkill;
