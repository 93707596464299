import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanCalonBankir = () => {
  const data = [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/",
      title: "Program Kami"
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP"
    }
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan Calon Bankir
              </div>
              <h2>Pendahuluan</h2>
              <p>
                Para Pegawai Perbankan dalam era industri 4.0 saat ini harus
                memiliki kapabilitas yang tinggi bukan hanya kemampuan dalam
                menjalankan bisnis Bank. Hal tersebut dikarenakan Pegawai
                Perbankan memiliki peran penting dalam mendukung keberhasilan
                pencapaian visi dan misi suatu Bank maka Kualitas dari para
                Pegawai Bank yang baik menjadi penting untuk mencapai misi
                tersebut.
              </p>
              <p>
                Upaya untuk mencapai kinerja tersebut tentunya harus sejalan
                dengan upaya untuk meningkatkan pengetahuan dalam pemahaman
                tentang General Banking,Kredit,Manajemen Risiko Perbankan dan
                Softskill. Diharapkan, dengan mengikuti program ini dapat
                memberikan pengetahuan kepada para Peserta mengenai Dasar-Dasar
                Perbankan.
              </p>
              <h2>Tujuan Program</h2>
              <p>
                Program pelatihan ini bertujuan untuk meningkatkan kemampuan
                Sumber Daya Manusia khususnya untuk para Staff seperti:
              </p>
              <ol>
                <li>Memahami tentang General Banking,</li>
                <li>Memahami tentang Kredit,</li>
                <li>Memahami tentang Risk Management.</li>
              </ol>
              <h2>Kurikulum Program</h2>
              <p>
                Kurikulum Program yang akan diberikan yaitu General Banking,
                Kredit, Risk Management, Softskill.
              </p>
              <h2>Metode Program</h2>
              <p>Diskusi interaktif, case study dan sharing knowledge</p>
              <h2>Rekomendasi Peserta</h2>
              <p>Pelatihan ini sangat direkomendasikan untuk diikuti oleh :</p>
              <ol>
                <li>Pegawai Dasar</li>
                <li>Calon Pegawai Dasar</li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanCalonBankir;
