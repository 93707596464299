import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/logo-white.png";

export default function Footer({ scrollToClients }) {
  const handleClick = () => {
    scrollToClients();
  };
  return (
    <>
      <footer>
        {/* <div className="circle-footer-1"></div> */}
        <Container style={{ height: "inherit" }}>
          <Row className="pt-5">
            <Col lg={5}>
              <div className="logo-footer">
                <Image src={logo} width={160} />
              </div>
              <div className="contact-list">
                <div className="contact-item p">
                  <span></span> 021-22059263 / contact@firm.co.id
                </div>
                <div className="contact-item p">
                  <span></span> 081318037222 (Jo Purba) /
                  prasojo.purba@firm.co.id
                </div>
                <div className="contact-item p">
                  <span></span> 0811173952 (Reza Achir) / reza.achir@firm.co.id
                </div>
                {/* <div className="contact-item p">
                  <span></span> (Ferry) / ferry.idroes@firm.co.id
                </div> */}
              </div>
            </Col>
            <Col lg={3} className="offset-lg-1">
              <div className="footer-title h5">Navigasi</div>
              <div className="link-menu">
                <a href="/tentang-kami/sekilas-tentang-firm" className="p">
                  Tentang Kami
                </a>
                <a href="/tentang-kami/sekilas-tentang-firm?ourclient=true">
                  Klien Kami
                </a>
                <a href="/tentang-kami/visi-misi-moto" className="p">
                  Visi & Misi
                </a>
                <a href="/tentang-kami/fasilitator" className="p">
                  Fasilitator
                </a>
              </div>
            </Col>
            <Col lg={3}>
              <div className="footer-title h5">Informasi Lainnya</div>
              <div className="address-menu p">
                Jl H. Saleh No. 44 Kemanggisan - Palmerah Jakarta
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="copy-right_content">Copyright © 2024. FiRM</div>
    </>
  );
}
