import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanAkuntansi = () => {
  const data = [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/",
      title: "Program Kami"
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP"
    }
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan Akuntansi
              </div>
              <h2>Pendahuluan</h2>
              <p>
                Laporan keuangan Bank memberikan gambaran dari kegiatan Bank.
                Akuntansi bank berperan untuk memilih, mengukur, dan melakukan
                agregasi aktivitas bank ke dalam laporan keuangan Bank. Para
                pengguna informasi laporan keuangan bank harus memahami bahwa
                laporan keuangan bank sangat dipengaruhi oleh bisnis bank dan
                sistem akuntansi bank.
              </p>
              <h2>Tujuan</h2>
              <ol>
                <li>Memahami tentang akuntansi dan laporan keuangan bank</li>
                <li>
                  Memahami dan menganalisis akuntansi dan laporan keuangan bank
                </li>
                <li>
                  Mengevaluasi potensi pemberian hasil terbaik dari calon
                  penerima penjaminan
                </li>
              </ol>
              <h2>Kurikulum</h2>
              <p>
                Kurikulum dalam pelatihan ini yaitu Laporan Posisi Keuangan,
                Laporan Laba / Rugi, Laporan Perubahan Ekuitas, Laporan Arus
                Kas, Analisis Rasio.
              </p>
              <h2>Metode Program</h2>
              <p>Diskusi interaktif, case study dan sharing knowledge</p>
              <h2>Rekomendasi Peserta</h2>
              <ol>
                <li>Pegawai di Unit Kerja Akuntnasi</li>
                <li>Calon Pegawai Bank</li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanAkuntansi;
