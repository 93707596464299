import { Col, Container, Image, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { menu } from "../common";
import arrowDown from "../../assets/arrow-down.svg";
import arrowDownBlack from "../../assets/arrow-down-black.svg";
import { useEffect, useRef, useState } from "react";
import menuHome from "../../assets/menu-mobile.svg";
import logo from "../../assets/logo-white.png";

export default function Header(props) {
  const { openMenuMobile } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let screenWidth = window.innerWidth;
    if (screenWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <header>
      <Container>
        {isMobile ? (
          <Row>
            <Col xs={8} className="d-flex align-items-center">
              <Image
                src={menuHome}
                width={30}
                height={30}
                onClick={openMenuMobile}
              />
            </Col>
            <Col xs={4} className="text-end">
              <a href="/">
                <Image
                  src={logo}
                  height={47}
                  className="py-2 w-100"
                  style={{ objectFit: "contain" }}
                />
              </a>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={2}>
              <a href="/">
                <Image
                  src={logo}
                  className="w-100"
                  height={47}
                  style={{
                    padding: "6px 42px"
                  }}
                />
              </a>
            </Col>
            <Col lg={10}>
              <div className="menu-list">
                {menu.map((item, index) => (
                  <MenuItem key={index} item={item} />
                ))}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </header>
  );
}

function MenuItem({ item }) {
  const anchorRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    const titleLength = item.title.length;
    const isTextLongEnough = titleLength > 25; // Change the threshold as needed

    setIsTextOverflowing(isTextLongEnough);
  }, [item.title]);

  return (
    <a
      className="menu-item"
      href={item.link}
      ref={anchorRef}
      data-tooltip-place="right"
      data-tooltip-id={`tooltip-${item.title}`}
      data-tooltip-content={item.title}
    >
      {item.title}{" "}
      {item.sub.length > 0 && (
        <>
          <span>
            <Image src={arrowDown} width={10} height={10} />
          </span>
          <div className="wrap-sub">
            <div className="menu-item_sub">
              {item.sub.map((subItem, subIndex) => (
                <div
                  className="d-flex justify-content-between"
                  style={{ alignItems: "baseline" }}
                  key={subIndex}
                >
                  <SubMenuItem
                    item={subItem}
                    idx={subIndex}
                    length={item.sub.length}
                  />
                  {subItem?.subsubmenu?.length > 0 && (
                    <Image src={arrowDownBlack} width={10} height={10} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {isTextOverflowing && <Tooltip id={`tooltip-${item.title}`} />}{" "}
      {/* Include the Tooltip component */}
    </a>
  );
}

function SubMenuItem({ item, idx, length }) {
  const anchorRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    const titleLength = item.title.length;
    const isTextLongEnough = titleLength > 25; // Change the threshold as needed

    setIsTextOverflowing(isTextLongEnough);
  }, [item.title]);

  return (
    <div className="sub-link">
      <a
        href={item.link}
        className={`${idx + 1 === length ? "mb-0" : ""}`}
        ref={anchorRef}
        data-tooltip-place="right"
        data-tooltip-id={`tooltip-${item.title}`} // Unique ID for tooltip
        data-tooltip-content={item.title} // Tooltip content
      >
        {item.title}
      </a>
      {isTextOverflowing && <Tooltip id={`tooltip-${item.title}`} />}
      {item.subsubmenu && item.subsubmenu.length > 0 && (
        <div className="wrap-sub-sub">
          <div className="sub-menu-item_sub">
            {item.subsubmenu.map((subsubItem, subsubIndex) => (
              <div
                className="d-flex justify-content-between"
                style={{ alignItems: "baseline" }}
                key={subsubIndex}
              >
                <SubSubMenuItem item={subsubItem} />
                {subsubItem.subsubsubmenu?.length > 0 && (
                  <Image src={arrowDownBlack} width={10} height={10} />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function SubSubMenuItem({ item }) {
  const anchorRef = useRef(null);
  const subSubSubAnchorRef = useRef(null); // Ref for subsubsubItem tooltip anchor
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [isSubSubSubItemTextOverflowing, setIsSubSubSubItemTextOverflowing] =
    useState(false);

  useEffect(() => {
    const titleLength = item.title.length;
    const isTextLongEnough = titleLength > 25; // Change the threshold as needed

    setIsTextOverflowing(isTextLongEnough);
  }, [item.title]);

  useEffect(() => {
    if (subSubSubAnchorRef.current) {
      const subSubSubAnchorElement = subSubSubAnchorRef.current;
      const subSubSubTitleLength = subSubSubAnchorElement.textContent.length;
      const isSubSubSubTextLongEnough = subSubSubTitleLength > 25; // Change the threshold as needed

      setIsSubSubSubItemTextOverflowing(isSubSubSubTextLongEnough);
    }
  }, [item.subsubsubmenu]);

  return (
    <div className="sub-sub-link">
      <a
        href={item.link}
        ref={anchorRef}
        data-tooltip-place="right"
        data-tooltip-id={`tooltip-${item.title}`} // Unique ID for tooltip
        data-tooltip-content={item.title} // Tooltip content
      >
        {item.title}
      </a>
      {isTextOverflowing && <Tooltip id={`tooltip-${item.title}`} />}
      {item.subsubsubmenu && item.subsubsubmenu.length > 0 && (
        <div className="wrap-sub-sub-sub">
          <div className="sub-sub-menu-item_sub">
            {item.subsubsubmenu.map((subsubsubItem, subsubsubIndex) => (
              <a
                key={subsubsubIndex}
                href={subsubsubItem.link}
                ref={subSubSubAnchorRef} // Ref for tooltip of subsubsubItem
                data-tooltip-id={`tooltip-${subsubsubItem.title}`} // Unique ID for tooltip
                data-tooltip-content={subsubsubItem.title} // Tooltip content
                data-tooltip-place="right" // Tooltip placement
              >
                {subsubsubItem.title}{" "}
              </a>
            ))}
          </div>
        </div>
      )}
      {isSubSubSubItemTextOverflowing && (
        <Tooltip id={`tooltip-${item.title}`} />
      )}{" "}
    </div>
  );
}
