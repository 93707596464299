import { Container, Row } from "react-bootstrap";
import MainLayout from "../components/layout/MainLayout";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";
import ActivitySection from "../components/modules/ActivitySection";
import TestimonialSection from "../components/modules/TestimonialSection";
import "./../style/testimonial.css";
import { activities } from "../components/common/content";

export default function GalleryTestimonials() {
  return (
    <MainLayout>
      <BreadcrumbSection label="Galeri" title="Galeri dan Testimonial" />
      <Container>
        <Row className="mb-5" style={{ marginTop: "72px" }}>
          {activities.map((_, i) => (
            <ActivitySection key={i} idx={i} data={_} />
          ))}
        </Row>
      </Container>
      <div className="testimonial-section">
        <TestimonialSection />
      </div>
    </MainLayout>
  );
}
