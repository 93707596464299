import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const OjkContent = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={9}>
          <div className="card-ojk">
            <h3 className="h3 fw-bold mb-4 title-in">
              Sertifikasi Manajemen Risiko Bagi Sumber Daya Manusia Bank Umum
            </h3>
            <div className="date">
              <div>
                Sektor:{" "}
                <span className="sektor-regulasi-display">Perbankan</span>
              </div>
              <br />
              <div>
                SubSektor:{" "}
                <span className="subsektor-regulasi-display">Bank Umum</span>
              </div>
              <br />
              <div>
                Jenis Regulasi:{" "}
                <span className="jenis-regulasi-display">Surat Edaran OJK</span>
              </div>
              <br />
              <div>
                Nomor Regulasi:{" "}
                <span className="nomor-regulasi-display">28/SEOJK.03/2022</span>
              </div>
              <br />
              <div>
                Tanggal Berlaku:{" "}
                <span className="display-date-text tanggal-2">
                  22 Desember 2022
                </span>
              </div>
            </div>
            <div className="attachments">
              <div style={{ display: "none" }}>Lampiran 1</div>
              <div className="ms-rtestate-field" style={{ display: "inline" }}>
                <p>
                  <a
                    href="https://ojk.go.id/id/regulasi/Documents/Pages/Sertifikasi-Manajemen-Risiko-Bagi-Sumber-Daya-Manusia-Bank-Umum/SEOJK%2028%20-%2003%20-%202022.pdf"
                    target="_blank"
                  >
                    <img
                      className="ms-asset-icon ms-rtePosition-4"
                      src="https://ojk.go.id/_layouts/15/images/icpdf.png"
                      alt=""
                    />
                    SEOJK 28 - 03 - 2022.pdf
                  </a>
                  <br />
                </p>
                <p>
                  <a
                    href="https://ojk.go.id/id/regulasi/Documents/Pages/Sertifikasi-Manajemen-Risiko-Bagi-Sumber-Daya-Manusia-Bank-Umum/RINGKASAN%20SEOJK%2028%20-%2003%20-%202022.pdf"
                    target="_blank"
                  >
                    <img
                      className="ms-asset-icon ms-rtePosition-4"
                      src="https://ojk.go.id/_layouts/15/images/icpdf.png"
                      alt=""
                    />
                    RINGKASAN SEOJK 28 - 03 - 2022.pdf
                  </a>
                  <br />
                </p>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              <div className="social-share">
                <span
                  className="st_facebook_hcount"
                  displaytext="Facebook"
                ></span>
                <span
                  className="st_twitter_hcount"
                  displaytext="Tweet"
                  st_via=""
                ></span>
                <span
                  className="st_plusone_hcount"
                  displaytext="Google +1"
                ></span>
              </div>
            </div>
            <div
              id="ctl00_PlaceHolderMain_ctl03__ControlWrapper_RichHtmlField"
              className="ms-rtestate-field"
              style={{ display: "inline" }}
            >
              <p style={{ textAlign: "justify" }}>
                Surat Edaran Otoritas Jasa Keuangan Republik Indonesia Nomor
                28/SEOJK.03/2022 Tentang Sertifikasi Manajemen Risiko Bagi
                Sumber Daya Manusia Bank Umum.
                <br />
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OjkContent;
