import { Button, Image } from "react-bootstrap";
import user from "../../assets/user.svg";
import "../../style/service.css";
import share from "../../assets/share.svg";
export default function TitleServiceSection(props) {
  const { title } = props;
  return (
    <div className="mb-4 d-flex _direction justify-content-between align-items-center">
      <div>
        <h2 className="title-service h2">{title ? title : "Title Service"}</h2>
        <div className="author-service">
          <span>
            <Image src={user} width={16} height={16} />
          </span>
          Admin
        </div>
      </div>
      <div className="cta-btn_wrap">
        <Button>
          Share{" "}
          <span>
            <Image src={share} width={18} height={18} />
          </span>
        </Button>
      </div>
    </div>
  );
}
