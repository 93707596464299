import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";

const ProgramPesiapanSertifikasiUlangLSPP = () => {
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <div className="content-service">
              <div className="content_title-service">
                Program Persiapan Sertifikasi Ulang (Resertifikasi)
              </div>
              <div className="content_subtitle-service">
                Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP
              </div>
              <div className="content_subtitle-service">Pendahuluan</div>
              <p>
                Bankir-bankir di industri perbankan Indonesia saat ini
                membutuhkan ketrampilan pengelolaan manajemen risiko sejalan
                dengan penetapan oleh OJK dimana setiap orang dalam bank harus
                memiliki kompetensi manajemen risiko sebagaimana tertuang dalam
                POJK No. 17/POJK.03/2014 tentang Penerapan Manajemen Risiko
                Terintegrasi bagi Konglomerasi Keuangan dan POJK No.
                18/POJK.03/2016 tentang Penerapan Manajemen Risiko Bagi Bank
                Umum. Pencegahan terjadinya potensi kerugian merupakan tujuan
                penting dari manajemen risiko perbankan. Perubahan yang cepat
                berubah sering terjadi di lingkungan internal dan eksternal
                perbankan sehingga dibutuhkan penerapan manajemen risiko secara
                benar.
              </p>

              <div className="content_subtitle-service">Tujuan Program</div>
              <ul>
                <li>
                  Mempersiapkan peserta untuk menghadapi Sertifikasi
                  Ulang/Resertifikasi KKNI bidang Manajemen Risiko Perbankan
                </li>
                <li>
                  Memberikan pemahaman komprehensif tentang Manajemen Risiko
                  Perbankan
                </li>
              </ul>

              <div className="content_subtitle-service">Kurikulum Program</div>
              <p>
                Kurikulum Program/Unit Kompetensi dari masing-masing Jenjang
                Kualifikasi sudah disesuaikan dengan SKKNI.
              </p>

              <div className="content_subtitle-service">Metode Program</div>
              <ul>
                <li>Diskusi interaktif</li>
                <li>Case study & sharing knowledge</li>
                <li>Simulasi Ujian</li>
              </ul>

              <div className="content_subtitle-service">
                Rekomendasi Peserta
              </div>
              <p>
                Program Persiapan Sertifikasi Ulang / Resertifikasi ini
                ditujukan bagi Pengurus, Pejabat, Direktur dan Komisaris bank
                yang sudah memiliki Sertifikat Kompetensi namun akan / akan
                jatuh tempo sehingga perlu dilakukan Sertifikasi Ulang. Program
                Persiapan ini terdiri dari beberapa Kualifikasi dengan kewajiban
                bagi pemegang sertifikat untuk mengikuti program pemeliharaan
                sesuai dengan kualifikasi sertifikatnya.
              </p>

              <div className="content_subtitle-service">
                Fasilitator Program
              </div>
              <p>
                Kami memiliki fasilitator berpengalaman dari industri perbankan
                dan regulator dimana para fasilitator tersebut sudah memiliki
                Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers
                untuk Pembekalan Manajemen Risiko.
              </p>

              <div className="content_subtitle-service">Investasi Program</div>
              <p>
                Kami memberikan paket investasi program yang menarik dengan
                pelayanan yang sangat membantu anda dalam meningkatkan
                kompetensi Sumber Daya Manusia di Bank.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPesiapanSertifikasiUlangLSPP;
