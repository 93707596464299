import { Container, Row } from "react-bootstrap";
import MainLayout from "../components/layout/MainLayout";

export default function TentangKami() {
  return (
    <MainLayout>
      <Container>
        <Row></Row>
      </Container>
    </MainLayout>
  );
}
