import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const BidangManajemenRisikoPerbankanJenjangKualifikasiBSMR = () => {
  const data = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/",
      title: "Program Kami",
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    },
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR
                (kolaborasi)
              </div>
              <div className="content_subtitle-service">Pendahuluan</div>
              <p>
                Sertifikasi Manajemen Risiko adalah merupakan kewajiban bagi
                Karyawan Bank dengan jenjang kualifikasi sesuai jabatan yang
                diemban yang didasarkan kepada ketentuan Bank Indonesia / OJK,
                agar para Pejabat yang berkewajiban memiliki Sertifikat
                Manajemen Risiko lebih siap dalam menghadapi ujian sertifikasi,
                kami menawarkan memberikan pelatihan agar Pejabat Bank mendapat
                predikat Kompeten dari BSMR. Melalui Proposal ini kami
                menawarkan bentuk pelatihan persiapan Ujian Sertifikasi
                Manajemen Risiko Jenjang Kualifikasi 4-5 yang diselenggarakan
                oleh BSMR. Materi pelatihan disesuaikan dengan materi ujian yang
                diselenggarakan BSMR dan pengajar yang akan memberikan pelatihan
                telah berpengalaman di berbagai Bank diseluruh Indonesia.
              </p>

              <div className="content_subtitle-service">Tujuan Program</div>
              <ul>
                <li>
                  Mempersiapkan peserta untuk menghadapi Sertifikasi
                  Ulang/Resertifikasi KKNI bidang Manajemen Risiko Perbankan
                </li>
                <li>
                  Memberikan pemahaman komprehensif tentang Manajemen Risiko
                  Perbankan
                </li>
              </ul>

              <div className="content_subtitle-service">Kurikulum Program</div>
              <p>
                Kurikulum Program/Unit Kompetensi dari masing-masing Jenjang
                Kualifikasi sudah disesuaikan dengan SKKNI.
              </p>

              <div className="content_subtitle-service">Metode Program</div>
              <ul>
                <li>Diskusi interaktif</li>
                <li>Case study & sharing knowledge</li>
                <li>Simulasi Ujian</li>
              </ul>

              <div className="content_subtitle-service">
                Rekomendasi Peserta
              </div>
              <p>
                Program Persiapan Uji Sertifikasi ini ditujukan bagi Pengurus
                dan Pejabat bank. Program Persiapan Uji Sertifikasi ini terdiri
                dari beberapa Kualifikasi dengan kewajiban bagi pemegang
                sertifikat untuk mengikuti program pemeliharaan sesuai dengan
                kualifikasi sertifikatnya.
              </p>

              <div className="content_subtitle-service">
                Fasilitator Program
              </div>
              <p>
                Kami memiliki fasilitator berpengalaman dari industri perbankan
                dan regulator dimana para fasilitator tersebut sudah memiliki
                Sertifikat Kompetensi Manajemen Risiko dan Training the Trainers
                untuk Pembekalan Manajemen Risiko.
              </p>

              <div className="content_subtitle-service">Investasi Program</div>
              <p>
                Kami memberikan paket investasi program yang menarik dengan
                pelayanan yang sangat membantu anda dalam meningkatkan
                kompetensi Sumber Daya Manusia di Bank.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default BidangManajemenRisikoPerbankanJenjangKualifikasiBSMR;
