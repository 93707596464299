import { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { menu } from "../common";
import { Image } from "react-bootstrap";
import arrowDownBlack from "../../assets/arrow-down-black.svg";

export default function MainLayout({ children }) {
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [id, setId] = useState(null);
  const [idSub, setIdSub] = useState(null);
  const [idSubSub, setIdSubSub] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubSubMenu, setOpenSubSubMenu] = useState(false);
  const [openSubSubSubMenu, setOpenSubSubSubMenu] = useState(false);
  const openMenuMobile = () => {
    setShowMenuMobile(!showMenuMobile);
  };

  const closeMenuMobile = (e) => {
    e.stopPropagation();
    setShowMenuMobile(!showMenuMobile);
  };

  const scrollToClients = () => {
    const clientsSection = document.getElementById("clients-section");
    if (clientsSection) {
      clientsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSelectMenu = (e, idx) => {
    e.stopPropagation();
    setId(idx);
    setOpenSubMenu(!openSubMenu);
  };

  const onSelectSubMenu = (e, idx) => {
    e.stopPropagation();
    setIdSub(idx);
    setOpenSubSubMenu(!openSubSubMenu);
  };

  const onSelectSubSubMenu = (e, idx) => {
    e.stopPropagation();
    setIdSubSub(idx);
    setOpenSubSubSubMenu(!openSubSubSubMenu);
  };

  return (
    <div
      className={`main-layout ${
        showMenuMobile ? "show-menu_mobile" : "hide-menu_mobile"
      }`}
    >
      <Header openMenuMobile={openMenuMobile} />
      {children}
      <Footer scrollToClients={scrollToClients} />
      {showMenuMobile && (
        <div className="sidebar-wrapper" onClick={closeMenuMobile}>
          <div className="sidebar-menu">
            {menu.map((item, index) => (
              <>
                <a
                  onClick={(e) => onSelectMenu(e, index)}
                  href={item.sub?.length > 0 ? "#" : item.link}
                >
                  <span className="title-nav">{item.title}</span>
                  {item.sub?.length > 0 && (
                    <span>
                      <Image src={arrowDownBlack} width={10} height={10} />
                    </span>
                  )}
                </a>

                {id === index &&
                  openSubMenu &&
                  item.sub?.length > 0 &&
                  item.sub?.map((subItem, subIndex) => (
                    <>
                      <a
                        href={
                          subItem.subsubmenu?.length > 0 ? "#" : subItem.link
                        }
                        key={subIndex}
                        onClick={(e) => onSelectSubMenu(e, subIndex)}
                        className="d-flex ps-1 justify-content-between"
                      >
                        <span className="title-nav">{subItem.title}</span>
                        {subItem.subsubmenu?.length > 0 && (
                          <span>
                            <Image
                              src={arrowDownBlack}
                              width={10}
                              height={10}
                            />
                          </span>
                        )}
                      </a>
                      {idSub === subIndex &&
                        openSubSubMenu &&
                        subItem?.subsubmenu?.map((subsubItem, subsubIndex) => (
                          <>
                            <a
                              href={
                                subsubItem?.subsubsubmenu?.length > 0
                                  ? "#"
                                  : subsubItem.link
                              }
                              onClick={(e) =>
                                onSelectSubSubMenu(e, subsubIndex)
                              }
                              key={subsubIndex}
                              className="d-flex ps-2 justify-content-between"
                            >
                              <span className="title-nav">
                                {subsubItem.title}
                              </span>
                              {subsubItem.subsubsubmenu?.length > 0 && (
                                <span>
                                  <Image
                                    src={arrowDownBlack}
                                    width={10}
                                    height={10}
                                  />
                                </span>
                              )}
                            </a>
                            {idSubSub === subsubIndex &&
                              openSubSubSubMenu &&
                              subsubItem?.subsubsubmenu?.map(
                                (subsubsubItem, subsubsubIndex) => (
                                  <a
                                    href={subsubsubItem.link}
                                    key={subsubsubIndex}
                                    className="d-flex ps-3 justify-content-between"
                                  >
                                    <span className="title-nav">
                                      {subsubsubItem.title}
                                    </span>
                                  </a>
                                )
                              )}
                          </>
                        ))}
                    </>
                  ))}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
