import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanPSAK = () => {
  const data = [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/",
      title: "Program Kami"
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP"
    }
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan PSAK
              </div>
              <h2>Pendahuluan</h2>
              <p>
                Aturan baru tentang Pernyataan Standar Akuntansi Keuangan (PSAK)
                71, 72 dan 73 akan mulai diimplementasikan. Ketiga PSAK ini
                merupakan bagian dari usaha otoritas akuntasi yaitu Dewan
                Standar Akuntansi Keuangan (DSAK) untuk mengadopsi sistem dari
                International Financial Reporting Standards (IFRS). Revisi pada
                standar pelaporan ini adalah sebagai respons terhadap sejumlah
                risiko yang kini dihadapi perusahaan-perusahaan, khususnya
                sektor keuangan atau finansial terhadap risiko kegagalan
                pembayaran kredit.
              </p>
              <h2>Tujuan</h2>
              <p>
                Peserta mampu memahami dan menjelaskan tentang Perkembangan
                terbaru tentang PSAK 71, 72 dan 73.
              </p>
              <h2>Kurikulum</h2>
              <p>Kurikulum dalam pelatihan ini yaitu PSAK 71, 72 dan 73.</p>
              <h2>Metode Program</h2>
              <p>Diskusi interaktif, case study dan sharing knowledge.</p>
              <h2>Rekomendasi Peserta</h2>
              <p>
                Pelatihan ini sangat direkomendasikan untuk diikuti oleh para
                karyawan di bidang Akuntansi dan Keuangan.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanPSAK;
