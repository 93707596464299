import React from "react";
import MainLayout from "../components/layout/MainLayout";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";
import { Col, Container, Image, Row } from "react-bootstrap";
import employee from "./../assets/firm-employee.jpeg";
import quoteUp from "./../assets/quote-up.svg";
import quoteDown from "./../assets/quote-down.svg";

const VisiMisiMoto = () => {
  return (
    <MainLayout>
      <BreadcrumbSection label="TENTANG KAMI" title="Visi, Misi, dan Motto" />
      <Container>
        <Row className="about-us_container mb-5">
          <Col lg={4} sm={12}>
            <div className="illustration-img">
              <Image src={employee} />
              <div className="icon-illustration"></div>
              <div className="shadow-img"></div>
            </div>
          </Col>
          <Col lg={7} className="offset-lg-1 px-4 px-lg-0" xs={12}>
            <h2 className="h2 fw-600 mb-3">Visi</h2>
            <div className="description-home mb-4">
              Menjadi organisasi pendidikan dan pelatihan yang profesional,
              berkualitas, dan berkomitmen dalam memberikan program pengembangan
              Sumber Daya Manusia.
            </div>
            <h2 className="h2 fw-600 mb-3">Misi</h2>
            <div className="description-home mb-4">
              <ul>
                <li>
                  Mengembangkan kompetensi Sumber Daya Manusia dalam bidang
                  Perbankan dan Manajemen Risiko Perbankan.
                </li>
                <li>
                  Menjamin kualitas program-program pengembangan Sumber Daya
                  Manusia.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center _moto">
          <Col lg={8}>
            <div className="moto-section">
              <Image src={quoteUp} className="_up" />
              <div className="text-center">
                <h2 className="moto-title h2  mb-4 fw-bold">Moto Kami</h2>
                <h4 className="h4 moto-desc">
                  We Develop with Quality
                  <br />
                  and Integrity
                </h4>
              </div>
              <Image src={quoteDown} className="_down"/>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default VisiMisiMoto;
