import "../../style/service.css";
import circle from "./../../assets/19406413.jpg";

export default function ImageServiceSection(props) {
  return (
    <div>
      <div className="image-service">
        <img src={circle} alt="circle" className="w-100" />
      </div>
    </div>
  );
}
