import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
const ProgramPendidikanCredit = () => {
  const data = [
    {
      link: "/",
      title: "Home"
    },
    {
      link: "/",
      title: "Program Kami"
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP"
    }
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Program Pendidikan Credit
              </div>
              <h2>Pendahuluan</h2>
              <p>
                Sebagai core business dalam industri perbankan, kredit memegang
                peran penting didalam menjaga dan memelihara keberlangsungan
                kegiatan usaha bisnis bank. Pengelolaan kredit yang profesional
                dengan tetap memperhatikan prinsip prudential banking wajib
                dimiliki dandijalankan oleh setiap sumber daya manusia
                perbankan. Guna mendukung pengelolaan kredit yang profesional,
                maka diperlukan pengetahuan yang memadai tentang tahapan, proses
                serta prosedur pengelolaan kredit.
              </p>
              <h2>Tujuan</h2>
              <p>
                Program ini bertujuan untuk melatih dan menghasilkan karyawan
                yang handal dan kompeten dalam melakukan analisis kredit untuk
                semua produk dan segmen kredit.
              </p>
              <h2>Kurikulum</h2>
              <p>
                Kurikulum dalam pelatihan ini yaitu Overview Kredit, Analisa
                Kredit, Hukum Kredit dan Penjaminan, Administrasi Kredit,
                Restrukturisasi Kredit dan NPL.
              </p>
              <h2>Metode Program</h2>
              <p>Diskusi interaktif, case study dan sharing knowledge</p>
              <h2>Rekomendasi Peserta</h2>
              <ol>
                <li>Para Analis Kredit</li>
                <li>Para AO Kredit</li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default ProgramPendidikanCredit;
