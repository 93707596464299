import { Button, Col, Container, Image, Row } from "react-bootstrap";
import MainLayout from "../components/layout/MainLayout";
import "../style/home.css";
import employee from "./../assets/firm-employee.jpeg";
import chart from "./../assets/chart.svg";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import ClientsSection from "../components/modules/ClientsSection";
import OurServiceSection from "../components/modules/OurServiceSection";
import ActivitySection from "../components/modules/ActivitySection";
import { activities } from "../components/common/content";

export default function Home() {
  return (
    <MainLayout>
      <div className="heroshot_container pt-5">
        <Container>
          <Row>
            <Col lg={7}>
              <h1 className="h1 mb-5">We Develop with Quality and Integrity</h1>
              <Button className="_btn-white _btn">Eksplor</Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="about-us_container">
        <Row>
          <Col lg={4} sm={12}>
            <div className="illustration-img">
              <Image src={employee} />
              <div className="icon-illustration"></div>
              <div className="shadow-img"></div>
            </div>
          </Col>
          <Col lg={7} className="offset-lg-1 px-4 px-lg-0" xs={12}>
            <div className="label-purple mt-5">About Us</div>
            <h1 className="h1 fw-600 mb-3">Sekilas Tentang FiRM</h1>
            <div className="description-home">
              PT Farash Inarah Radhika Mahaprana disebut juga dengan FiRM adalah
              badan hukum yang bergerak di bidang pendidikan, berdomisili di
              Jakarta, Indonesia. FiRM berdiri sejak tahun 2019 dengan tujuan
              untuk mengembangkan keilmuan dalam bidang Perbankan, Manajemen
              Risiko Perbankan dan Soft Skills.
            </div>
          </Col>
        </Row>
      </Container>
      <OurServiceSection />
      <Container className="services_container">
        <Row>
          <Col lg={12} className="d-flex flex-column align-items-center">
            <div className="label-purple">Activity</div>
            <h1 className="h1 fw-600 _title-custom">Kegiatan Kami</h1>
          </Col>
        </Row>
        <Row>
          {activities.map((_, i) => (
            <ActivitySection key={i} idx={i} data={_} />
          ))}
        </Row>
      </Container>
      <ClientsSection />
    </MainLayout>
  );
}
