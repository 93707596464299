import { Container, Row, Col } from "react-bootstrap";
import MainLayout from "../components/layout/MainLayout";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";

export default function AktifitasKami() {
  return (
    <MainLayout>
      <BreadcrumbSection title="Aktifitas Kami" />
      <Container>
        <Row className="about-us_container">
          <Col>
            <h2>Program Persiapan Uji Sertifikasi</h2>
            <h4>
              Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP
            </h4>
            <p>
              Pendahuluan...
              <br />
              Tujuan Program: Memastikan peserta siap menghadapi ujian
              sertifikasi dengan komprehensif.
              <br />
              Metode Program: Diskusi interaktif, studi kasus, dan simulasi
              ujian.
              <br />
              Rekomendasi Peserta: Direktur, Manajer Risiko, dan staf yang
              terlibat dalam manajemen risiko bank.
            </p>
            {/* Add more content here */}
            <h4>
              Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR
              (kolaborasi)
            </h4>
            <p>
              Pendahuluan...
              <br />
              Tujuan Program: Menyiapkan peserta agar dapat lulus ujian
              sertifikasi BSMR dengan predikat kompeten.
              <br />
              Metode Program: Diskusi interaktif, pembelajaran berbasis kasus,
              dan simulasi ujian.
              <br />
              Rekomendasi Peserta: Pejabat bank yang memegang peran penting
              dalam manajemen risiko.
            </p>
            {/* Add more content here */}
            <h4>Bidang General Banking Tingkat 1-3, LSPP</h4>
            <p>
              Pendahuluan...
              <br />
              Tujuan Program: Mempersiapkan peserta untuk menguasai bidang
              general banking dengan baik.
              <br />
              Metode Program: Diskusi interaktif, studi kasus, dan latihan
              simulasi.
              <br />
              Rekomendasi Peserta: Karyawan bank pada tingkat awal karir di
              divisi general banking.
            </p>
            {/* Add more content here */}
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
