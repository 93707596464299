import { Col, Image } from "react-bootstrap";
import bgDummy from "./../../assets/bg.jpg";

export default function ActivitySection(props) {
  const { data, idx } = props;
  return (
    <Col lg={idx == 3 ? 12 : 4} sm={6} xs={12}>
      <div className="card-common _square">
        <Image src={data.img} />
        <div className="overlay-card">
          <div>
            <div className="title-card_overlay">HSE</div>
            <div className="desc-card_overlay">
              {data.title}
            </div>
          </div>
          <div className="more-btn-card fw-600">Lihat Selengkapnya</div>
        </div>
      </div>
    </Col>
  );
}
