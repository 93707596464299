import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";
import MainLayout from "../components/layout/MainLayout";
import "./../style/news.css";
import user from "../assets/user.svg";
import calendar from "../assets/calendar.svg";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";

export default function Berita() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://dashboard.firm.co.id/api/posts"
        );
        setPosts(response.data.data.data); // Access the posts array in the response
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const redirectToSlug = (slug) => {
    const width = 900;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const options = `width=${width},height=${height},left=${left},top=${top}`;

    window.open(slug, "", options);
  };

  return (
    <MainLayout>
      <BreadcrumbSection label="Berita & Pengumuman" title="Berita Perbankan" />

      <Container className="container-berita">
        <Row>
          {loading ? (
            <p className="text-center w-100">Loading...</p>
          ) : error ? (
            <p className="text-center w-100">Error: {error.message}</p>
          ) : (
            currentPosts.map((post) => (
              <Col
                lg={4}
                key={post.id}
                onClick={() => redirectToSlug(post.slug)}
              >
                <div className="card-berita">
                  <div>
                    <div className="image-berita">
                      <Image
                        src={`https://dashboard.firm.co.id${post.image.medium_url}`}
                      />
                    </div>
                    <h5 className="title-berita-card h5">{post.title}</h5>
                  </div>
                  <div className="info-berita">
                    <span>
                      <Image src={user} width={16} height={16} />
                      {post.user.name} {/* Access user's name */}
                    </span>{" "}
                    |{" "}
                    <span>
                      <Image src={calendar} width={16} height={16} />{" "}
                      {dayjs(post.published_at).format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>

        <div className="text-center d-flex justify-content-center">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </Container>
    </MainLayout>
  );
}

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <a onClick={(e) => { e.preventDefault(); paginate(number); }} href="javascript:void(0);" className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
