import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import "./style/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import AktifitasKami from "./pages/AktifitasKami";
import Berita from "./pages/Berita";
import Regulator from "./pages/Regulator";
import TentangKami from "./pages/TentangKami";
import GalleryTestimonials from "./pages/GalleryTestimonials";
import BidangManajemenRisikoPerbankanJenjangKualifikasiLSPP from "./pages/DetailActivity";
import BidangManajemenRisikoPerbankanJenjangKualifikasiBSMR from "./pages/BidangManajemenRisikoPerbankanJenjangKualifikasiBSMR";
import BidangGeneralBankingLSPP from "./pages/BidangGeneralBankingLSPP";
import ProgramPesiapanSertifikasiUlangLSPP from "./pages/ProgramPesiapanSertifikasiUlangLSPP";
import ProgramPesiapanSertifikasiUlangBSMR from "./pages/ProgramPesiapanSertifikasiUlangBSMR";
import PemeliharaanSertifikasiLSPP from "./pages/PemeliharaanSertifikasiLSPP";
import PemeliharaanSertifikasiBSMR from "./pages/PemeliharaanSertifikasiBSMR";
import ProgramPendidikanCalonBankir from "./pages/ProgramPendidikanCalonBankir";
import ProgramPendidikanRiskManagement from "./pages/ProgramPendidikanRiskManajemen";
import ProgramPendidikanAkuntansi from "./pages/ProgramPendidikanAkuntansi";
import ProgramPendidikanPSAK from "./pages/ProgramPendidikanPSAK";
import ProgramPendidikanCredit from "./pages/ProgramPendidikanCredit";
import ProgramPendidikanFrontliner from "./pages/ProgramPendidikanFrontliner";
import ProgramPendidikanSoftSkill from "./pages/ProgramPendidikanSoftSkill";
import SekilasTentangFiRM from "./pages/SekilasTentangFiRM";
import VisiMisiMoto from "./pages/VisiMisi";
import DewanPenasehat from "./pages/DewanPenasehat";
import Fasilitator from "./pages/Fasilitator";
import PendampinganHinggaDinyatakanKompeten from "./pages/PendampinganHinggaDinyatakanKompeten";
import DetailActivity from "./pages/DetailActivity";

const App = () => {
  const routes = useRoutes([
    {
      title: "Beranda",
      path: "/",
      element: <Home />,
    },
    {
      title: "Aktifitas Kami",
      path: "/aktifitas-kami",
      element: <AktifitasKami />,
    },
    {
      title: "Aktifitas Kami",
      path: "/aktifitas-kami/:title/:subtitle/:id",
      element: <DetailActivity />,
    },
    {
      title:
        "Program Persiapan Uji Sertifikasi Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
      path: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-manajemen-risiko-lspp",
      element: <BidangManajemenRisikoPerbankanJenjangKualifikasiLSPP />,
    },
    {
      title:
        "Program Persiapan Uji Sertifikasi Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
      path: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-manajemen-risiko-bsmr",
      element: <BidangManajemenRisikoPerbankanJenjangKualifikasiBSMR />,
    },
    {
      title:
        "Program Persiapan Uji Sertifikasi Bidang General Banking Tingkat 1-3, LSPP",
      path: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-general-banking-lspp",
      element: <BidangGeneralBankingLSPP />,
    },
    {
      title:
        "Program Persiapan Sertifikasi Ulang Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
      path: "/aktifitas-kami/program-persiapan-sertifikasi-ulang/bidang-manajemen-risiko-lspp",
      element: <ProgramPesiapanSertifikasiUlangLSPP />,
    },
    {
      title: "Pendampingan hingga dinyatakan Kompeten",
      path: "/aktifitas-kami/program-persiapan-uji-sertifikasi/bidang-manajemen-risiko-lspp/pendampingan-hingga-dinyatakan-kompeten",
      element: <PendampinganHinggaDinyatakanKompeten />,
    },
    {
      title: "Pendampingan hingga dinyatakan Kompeten",
      path: "/aktifitas-kami/program-persiapan-sertifikasi-ulang/bidang-manajemen-risiko-lspp/pendampingan-hingga-dinyatakan-kompeten",
      element: <PendampinganHinggaDinyatakanKompeten />,
    },
    {
      title:
        "Program Persiapan Sertifikasi Ulang Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
      path: "/aktifitas-kami/program-persiapan-sertifikasi-ulang/bidang-manajemen-risiko-bsmr",
      element: <ProgramPesiapanSertifikasiUlangBSMR />,
    },
    {
      title:
        "Program Pemeliharaan (Refreshment) Sertifikasi Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
      path: "/aktifitas-kami/program-pemeliharaan-sertifikasi/bidang-manajemen-risiko-lspp",
      element: <PemeliharaanSertifikasiLSPP />,
    },
    {
      title:
        "Program Pemeliharaan (Refreshment) Sertifikasi Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-5, BSMR (kolaborasi)",
      path: "/aktifitas-kami/program-pemeliharaan-sertifikasi/bidang-manajemen-risiko-bsmr",
      element: <PemeliharaanSertifikasiBSMR />,
    },
    {
      title:
        "Program Pendidikan dan Pelatihan General Banking untuk Calon Bankir",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/general-banking-untuk-calon-bankir",
      element: <ProgramPendidikanCalonBankir />,
    },
    {
      title: "Program Pendidikan dan Pelatihan Risk Management Program",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/risk-management-program",
      element: <ProgramPendidikanRiskManagement />,
    },
    {
      title:
        "Program Pendidikan dan Pelatihan Akuntansi dan Laporan Keuangan Bank",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/akuntansi-dan-laporan-keuangan-bank",
      element: <ProgramPendidikanAkuntansi />,
    },
    {
      title: "Program Pendidikan dan Pelatihan PSAK 71,72 & 73",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/psak-71-72-73",
      element: <ProgramPendidikanPSAK />,
    },
    {
      title: "Program Pendidikan dan Pelatihan Credit Management",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/credit-management",
      element: <ProgramPendidikanCredit />,
    },
    {
      title: "Program Pendidikan dan Pelatihan Frontliner Operation",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/frontliner-operation",
      element: <ProgramPendidikanFrontliner />,
    },
    {
      title: "Program Pendidikan dan Pelatihan Soft Skills",
      path: "/aktifitas-kami/program-pendidikan-dan-pelatihan/soft-skills",
      element: <ProgramPendidikanSoftSkill />,
    },
    {
      title: "Berita & Pengumuman",
      path: "/berita-perbankan",
      element: <Berita />,
    },
    {
      title: "Peraturan Regulator",
      path: "/regulasi",
      element: <Regulator />,
    },
    {
      title: "Tentang Kami",
      path: "/tentang-kami",
      element: <TentangKami />,
    },
    {
      title: "Sekilas Tentang FiRM",
      path: "/tentang-kami/sekilas-tentang-firm",
      element: <SekilasTentangFiRM />,
    },
    {
      title: "Visi Misi Moto",
      path: "/tentang-kami/visi-misi-moto",
      element: <VisiMisiMoto />,
    },
    {
      title: "Dewan Penasehat",
      path: "/tentang-kami/dewan-penasehat",
      element: <DewanPenasehat />,
    },
    {
      title: "Fasilitator",
      path: "/tentang-kami/fasilitator",
      element: <Fasilitator />,
    },
    {
      title: "Gallery & Testimonials",
      path: "/galeri-dan-testimonials",
      element: <GalleryTestimonials />,
    },
  ]);

  return routes;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
