import { Col, Container, Row } from "react-bootstrap";
import MainLayout from "../components/layout/MainLayout";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";
import OjkContent from "../components/OjkContent";

export default function Regulator() {
  return (
    <MainLayout>
      <BreadcrumbSection title="Peraturan Regulator" />
      <Container>
        <Row className="about-us_container">
          <Col>
            <OjkContent />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
