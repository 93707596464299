import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Image, Row } from "react-bootstrap";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";
import ClientsSection from "../components/modules/ClientsSection";
import "./../style/about.css";
import employee from "../assets/firm-employee.jpeg";

const SekilasTentangFiRM = () => {
  const myRef = useRef(null);
  const clientsSectionRef = useRef(null);
  const location = useLocation();

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("ourclient") === "true") {
      executeScroll();
    }
  }, [location.search]);

  return (
    <MainLayout>
      <BreadcrumbSection label="TENTANG KAMI" title="Sekilas Tentang FiRM" />
      <Container>
        <Row className="about-us_container">
          <Col lg={6}>
            <h2 className="h2 fw-600 mb-3">Tentang FiRM</h2>
            <div className="about-us_text">
              PT Farash Inarah Radhika Mahaprana, disingkat sebagai FiRM, adalah
              sebuah badan hukum yang bergerak di bidang pendidikan,
              berkedudukan di Jakarta, Indonesia. FiRM telah aktif sejak tahun
              2019 dengan fokus utama pada pengembangan ilmu pengetahuan dalam
              bidang Perbankan, Manajemen Risiko Perbankan, dan Soft Skills.
            </div>
            <div className="about-us_text">
              FiRM telah menyelenggarakan berbagai program pelatihan bagi
              klien-klien di sektor perbankan di Indonesia. Pendekatan praktis
              menjadi salah satu keunggulan FiRM dalam menyampaikan pengetahuan
              dan keterampilan di bidang perbankan, melalui metode seperti
              berbagi pengetahuan dan pengalaman serta studi kasus dan simulasi.
            </div>
          </Col>
          <Col lg={5} className="offset-lg-1">
            <div className="img-about-us">
              <Image src={employee} />
            </div>
          </Col>
        </Row>
        <ClientsSection ref={clientsSectionRef} />
        <div ref={myRef}></div>
      </Container>
    </MainLayout>
  );
};

export default SekilasTentangFiRM;
