import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../../style/home.css";
import chart from "./../../assets/chart.svg";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import arrowCircleRight from "./../../assets/arrow-circle-right.svg";
import circle from "./../../assets/circle.png";

export default function OurServiceSection() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function renderSplideInterest() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    const services = [
      {
        title: "Online Training",
        description:
          "FiRM, penyedia layanan training dan konsultasi, menyelenggarakan pelatihan online.",
      },
      {
        title: "Offline Training",
        description: "FiRM, penyedia layanan training dan konsultasi, menyelenggarakan pelatihan tatap muka.",
      },
    ];

    return (
      <div className="wrap-category">
        {/* <Row>
          <Col lg={12} className="mt-4"> */}
        <div className="category-card">
          <div>
            <Splide
              hasTrack={true}
              options={{
                rewind: true,
                perPage: 3,
                fixedWidth: "361px",
                perMove: 1,
                gap: "1.5rem",
                pagination: false,
                arrows: false,
                breakpoints: {
                  992: {
                    perPage: 3,
                  },
                  768: {
                    perPage: 2,
                  },
                  500: {
                    perPage: 1,
                  },
                },
              }}
            >
              {services.map((e, i) => (
                <SplideSlide key={i}>
                  <div className="item">
                    <div className="card-common _services">
                      <div>
                        <Image src={chart} height={78} width={78} className="mb-4"/>
                        <div className="title-services_item h4">{e.title}</div>
                        <div className="description-services_item h6">
                          {e.description}
                        </div>
                      </div>
                      <div>
                        <div className="d-flex justify-content-end w-100">
                          <button className="btn-services_cta h6">
                            <span className="text_more">Selengkapnya</span>
                            <span>
                              <Image
                                src={arrowCircleRight}
                                width={10}
                                height={10}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
        {/* </Col>
        </Row> */}
      </div>
    );
  }

  return (
    <div className="background-secondary _height-main position-relative">
      <Container className="h-100 position-relative" style={{ zIndex: 2 }}>
        <Row className="h-100 align-items-center">
          <Col lg={4}>
            <div className="label-purple">Our Services</div>
            <h1 className="h1 fw-600">Our Services</h1>
          </Col>
        </Row>
      </Container>
      {loaded ? renderSplideInterest() : ""}
      <div className="position-absolute _circle-ornament">
        <img src={circle} alt="circle" className="w-100" />
      </div>
    </div>
  );
}
