import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
import "./../style/service.css";

const BidangGeneralBankingLSPP = () => {
  const data = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/",
      title: "Program Kami",
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    },
  ];
  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                Bidang General Banking Tingkat 1-3, LSPP
              </div>
              <div className="content_subtitle-service">Pendahuluan</div>
              <p>
                Persiapan menghadapi era industri 4.0 perbankan nasional harus
                meningkatkan kapabilitas untuk dapat bersaing dengan
                negara-negara di dunia. Sumber Daya Manusia (SDM) merupakan
                salah satu komponen yang perlu dipersiapkan, karena SDM tidak
                hanya sebagai pemberi nilai tambah tetapi juga merupakan ujung
                tombak (first line of defense) dalam menghadapi berbagai
                tantangan yang ada dalam industri perbankan baik nasional maupun
                internasional. Kompetensi kerja diperlukan dalam rangka
                pemberdayaan SDM yang optimal dan tepat. Kompetensi SDM yang
                bervariasi menuntut adanya suatu standar kompetensi kerja, yang
                dapat dijadikan sumber alat ukur untuk menilai kompetensi yang
                dikuasainya. Standar kompetensi yang umum berlaku di Indonesia
                disebut dengan Standar Kompetensi Kerja Nasional Indonesia
                (SKKNI). Sektor Keuangan Sub Sektor Perbankan telah memiliki
                SKKNI 9 (sembilan) bidang profesi yaitu Manajemen Risiko,
                Treasury, Wealth Management, Audit Intern Bank, Compliance,
                Operation, Funding and Services, Operation, dan General Banking
                yang disusun oleh Ikatan Bankir Indonesia (IBI). SKKNI bidang
                General Banking disusun karena bidang kerja serta jumlah bankir
                yang terdapat dalam General Banking di suatu bank umumnya lebih
                banyak dibandingkan dengan bidang spesialis. Berdasarkan hal
                tersebut maka perlu adanya standardisasi kompetensi di bidang
                General Banking.
              </p>

              <div className="content_subtitle-service">Tujuan Program</div>
              <ul>
                <li>
                  Mempersiapkan peserta untuk menghadapi uji Sertifikasi KKNI
                  bidang General Banking
                </li>
                <li>
                  Memberikan pemahaman komprehensif tentang General Banking
                </li>
              </ul>

              <div className="content_subtitle-service">Kurikulum Program</div>
              <p>
                Kurikulum Program dari masing-masing Tingkat / Level sudah
                disesuaikan dengan SKKNI.
              </p>

              <div className="content_subtitle-service">Metode Program</div>
              <ul>
                <li>Diskusi interaktif</li>
                <li>Case study & sharing knowledge</li>
                <li>Simulasi Ujian</li>
              </ul>

              <div className="content_subtitle-service">
                Rekomendasi Peserta
              </div>
              <p>
                Program Persiapan Uji Sertifikasi ini ditujukan bagi pengurus
                dan pejabat bank. Program Persiapan Uji Sertifikasi ini terdiri
                dari beberapa Kualifikasi dengan kewajiban bagi pemegang
                sertifikat untuk mengikuti program pemeliharaan sesuai dengan
                kualifikasi sertifikatnya.
              </p>

              <div className="content_subtitle-service">
                Fasilitator Program
              </div>
              <p>
                Kami memiliki fasilitator berpengalaman dari industri perbankan
                dan regulator dimana para fasilitator tersebut sudah memiliki
                Sertifikat Kompetensi General Banking.
              </p>
              <div className="content_subtitle-service">Investasi Program</div>
              <p>
                Kami memberikan paket investasi program yang menarik dengan
                pelayanan yang sangat membantu anda dalam meningkatkan
                kompetensi Sumber Daya Manusia di Bank.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default BidangGeneralBankingLSPP;
