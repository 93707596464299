import { Container, Row, Col } from "react-bootstrap";
import MainLayout from "../components/layout/MainLayout";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";

export default function PendampinganHinggaDinyatakanKompeten() {
  return (
    <MainLayout>
      <BreadcrumbSection title="Pendampingan hingga dinyatakan Kompeten" />
      <Container>
        <Row className="about-us_container">
          <Col>
            <h2>Pendampingan hingga dinyatakan Kompeten</h2>
            <h6>
              FiRM berkomitmen pada tingkat kelulusan di atas 90% dari total
              peserta pelatihan. Klausula yang menjamin garansi tersebut adalah:
              “Kami memberikan Ujian ulang kepada peserta yang dinyatakan belum
              kompeten setiap ketidaklulusan dibawah 90%”.
            </h6>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
