import { useEffect, useState } from "react";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { clients } from "../common";
import quoteUp from "../../assets/quote-up.svg";

export default function TestimonialSection() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);
  function renderClient() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row className="my-5">
        <div className="category-card">
          <div>
            <Splide
              hasTrack={true}
              options={{
                rewind: true,
                perPage: 1,
                // fixedWidth: "361px",
                perMove: 1,
                gap: "1.5rem",
                pagination: false,
                arrows: true,
                breakpoints: {
                  992: {
                    perPage: 3,
                  },
                  768: {
                    perPage: 2,
                  },
                  500: {
                    perPage: 1,
                  },
                },
              }}
            >
              {clients.map((elm, i) => (
                <SplideSlide key={i}>
                  <div className="testimoni-item">
                    <div className="testimoni-quote">
                      <Image src={quoteUp} />
                    </div>
                    <div className="testimoni-desc">
                      “Lorem ipsum doller sit amet lorem ipsum doler sit amet”
                    </div>
                    <div className="testimoni-klien">
                      <div className="klien-img"></div>
                      <div className="klien-name"></div>
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </Row>
    );
  }
  return (
    <Container>
      <Row>
        <Col lg={12} className="d-flex flex-column align-items-center">
          <div className="label-purple">Testimoni</div>
          <h1 className="h1 fw-600">Our Testimonial</h1>
        </Col>
      </Row>
      {loaded ? renderClient() : ""}
    </Container>
  );
}
