import React from "react";
import MainLayout from "../components/layout/MainLayout";
import { Container, Row } from "react-bootstrap";
import BreadcrumbSection from "../components/modules/BreadcrumbSection";

const Fasilitator = () => {
  return (
    <MainLayout>
      <BreadcrumbSection label="TENTANG KAMI" title="Fasilitator" />
      <Container>
        <Row className="about-us_container">
          <div>
            <p>
              FiRM memiliki banyak fasilitator berpengalaman baik dari Industri
              Perbankan, Regulator, dan Lembaga Pemerintahan. Para fasilitator
              tersebut memiliki kemampuan dan keahlian di bidangnya
              masing-masing serta memiliki Sertifikat Kompetensi.
            </p>
          </div>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default Fasilitator;
