import React, { useEffect, useState } from "react";
import MainLayout from "../components/layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import "./../style/service.css";
import BreadcrumbServiceSection from "../components/modules/BreadcrumbServiceSection";
import TitleServiceSection from "../components/modules/TitleServiceSection";
import ImageServiceSection from "../components/modules/ImageServiceSection";
import { contentServices } from "../components/common/content";
import { useParams } from "react-router-dom";
const DetailActivity = () => {
  const { id, title, subtitle } = useParams();
  const [showContent, setShowContent] = useState(null);
  const data = [
    {
      link: "/",
      title: "Home",
    },
    {
      link: "/",
      title: "Program Kami",
    },
    {
      link: "/",
      title: "Bidang Manajemen Risiko Perbankan Jenjang Kualifikasi 4-7, LSPP",
    },
  ];

  useEffect(() => {
    let selectedData = contentServices.find((item) => item.id == id);
    if (selectedData) {
      setShowContent(selectedData);
    }
  }, [id]);

  const convertToProgramPersiapanUjiSertifikasi = (inputText) => {
    // Lakukan konversi string di sini, misalnya:
    const convertedText = inputText
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return convertedText;
  };

  return (
    <MainLayout>
      <Container className="mt-5 detail-service">
        <Row className="justify-content-center pt-5">
          <Col lg={10}>
            <BreadcrumbServiceSection data={data} />
            <TitleServiceSection title={showContent?.title} />
            <ImageServiceSection />
            <div className="content-service">
              <div className="content_title-service">
                {convertToProgramPersiapanUjiSertifikasi(title)}
              </div>
              <div className="content_subtitle-service">
                {showContent?.content[0]?.title}
              </div>
              <p>{showContent?.content[0]?.description}</p>
              <div className="content_subtitle-service">
                {showContent?.content[1]?.title}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: showContent?.content[1]?.description,
                }}
              />

              <div className="content_subtitle-service">
                {showContent?.content[2]?.title}
              </div>
              <p>{showContent?.content[2]?.description}</p>
              <div className="content_subtitle-service">
                {showContent?.content[3]?.title}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: showContent?.content[3]?.description,
                }}
              />
              <div className="content_subtitle-service">
                {showContent?.content[4]?.title}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: showContent?.content[4]?.description,
                }}
              />
              <div className="content_subtitle-service">
                {showContent?.content[5]?.title}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: showContent?.content[5]?.description,
                }}
              />
              <div className="content_subtitle-service">
                {showContent?.content[6]?.title}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: showContent?.content[6]?.description,
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default DetailActivity;
