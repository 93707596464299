import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { clients } from "../common";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "../../style/home.css";

const ClientsSection = React.forwardRef((props, ref) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function renderClient() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row className="_client-list">
        <div className="category-card  ">
          <div>
            <Splide
              hasTrack={true}
              options={{
                rewind: true,
                perPage: 4,
                // fixedWidth: "361px",
                perMove: 1,
                gap: "1.5rem",
                pagination: false,
                arrows: true,
                breakpoints: {
                  992: {
                    perPage: 3,
                  },
                  768: {
                    perPage: 2,
                  },
                  500: {
                    perPage: 1,
                  },
                },
              }}
            >
              {clients.map((elm, i) => (
                <SplideSlide key={i}>
                  <div style={{ width: "100%" }}>
                    <Image src={elm.img} className="w-100 _client-item" />
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </Row>
    );
  }
  return (
    <Container>
      <Row>
        <Col lg={12} className="d-flex flex-column align-items-center">
          <div className="label-purple">CLIENT</div>
          <h1 className="h1 fw-600">Our Client</h1>
        </Col>
      </Row>
      {loaded ? renderClient() : ""};
    </Container>
  );
});

export default ClientsSection;